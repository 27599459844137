/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Permission } from '../models/Permission';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RoleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all roles
     * @returns void 
     * @throws ApiError
     */
    public getRole(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/role/',
        });
    }

    /**
     * Create role
     * @param requestBody 
     * @returns any Id(id: int)
     * @throws ApiError
     */
    public postRole(
requestBody?: {
name: string;
permission_ids: Array<number>;
},
): CancelablePromise<{
id: number;
}> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/role/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete role
     * @param roleId 
     * @returns void 
     * @throws ApiError
     */
    public deleteRole(
roleId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/role/{role_id}',
            path: {
                'role_id': roleId,
            },
        });
    }

    /**
     * Get single roles
     * @param roleId 
     * @returns any RoleWithPermissions(id: 'int', name: 'str', permissions: 'list[Permission]')
     * @throws ApiError
     */
    public getRole1(
roleId: number,
): CancelablePromise<{
id: number;
name: string;
permissions: Array<Permission>;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/role/{role_id}',
            path: {
                'role_id': roleId,
            },
        });
    }

    /**
     * Update role permissions
     * @param roleId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchRole(
roleId: number,
requestBody?: {
permission_ids: Array<number>;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/role/{role_id}',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
