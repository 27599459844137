/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PartService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleButtonPcb(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-button-pcb/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleButtonPcb(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-button-pcb/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleButtonPcb(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-button-pcb/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleButtonPcb1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-button-pcb/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleButtonPcb(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-button-pcb/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleButton(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-button/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleButton(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-button/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleButton(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-button/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleButton1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-button/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleButton(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-button/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleCoil(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-coil/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleCoil(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-coil/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleCoil(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-coil/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleCoil1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-coil/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleCoil(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-coil/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all colors
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleColor(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-color/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new color
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleColor(
requestBody?: {
name: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-color/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete color
     * @param colorId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleColor(
colorId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-color/{color_id}',
            path: {
                'color_id': colorId,
            },
        });
    }

    /**
     * Get color by id
     * @param colorId 
     * @returns any HeatleColor(name: str, id: int)
     * @throws ApiError
     */
    public getHeatleColor1(
colorId: number,
): CancelablePromise<{
id: number;
name: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-color/{color_id}',
            path: {
                'color_id': colorId,
            },
        });
    }

    /**
     * Update color
     * @param colorId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleColor(
colorId: number,
requestBody?: {
name: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-color/{color_id}',
            path: {
                'color_id': colorId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleFan(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-fan/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleFan(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-fan/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleFan(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-fan/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleFan1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-fan/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleFan(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-fan/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleGlass(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-glass/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleGlass(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-glass/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleGlass(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-glass/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleGlass1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-glass/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleGlass(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-glass/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleGlue(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-glue/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleGlue(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-glue/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleGlue(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-glue/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleGlue1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-glue/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleGlue(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-glue/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleHeatsink(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-heatsink/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleHeatsink(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-heatsink/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleHeatsink(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-heatsink/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleHeatsink1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-heatsink/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleHeatsink(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-heatsink/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleLedPcb(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-led-pcb/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleLedPcb(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-led-pcb/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleLedPcb(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-led-pcb/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleLedPcb1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-led-pcb/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleLedPcb(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-led-pcb/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleMosfet(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-mosfet/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleMosfet(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-mosfet/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleMosfet(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-mosfet/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleMosfet1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-mosfet/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleMosfet(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-mosfet/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleSticker(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-sticker/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleSticker(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-sticker/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleSticker(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-sticker/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleSticker1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-sticker/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleSticker(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-sticker/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get many heatle parts
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleWire(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-wire/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new part
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleWire(
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-wire/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete part
     * @param partId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleWire(
partId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-wire/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Get part by id
     * @param partId 
     * @returns any HeatlePart(name: str, manufacturer_name: Optional[str], manufacturer_part_number: Optional[str], order_information: Optional[str], id: int)
     * @throws ApiError
     */
    public getHeatleWire1(
partId: number,
): CancelablePromise<{
id: number;
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-wire/{part_id}',
            path: {
                'part_id': partId,
            },
        });
    }

    /**
     * Update part
     * @param partId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleWire(
partId: number,
requestBody?: {
manufacturer_name: string;
manufacturer_part_number: string;
name: string;
order_information: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-wire/{part_id}',
            path: {
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
