import { create } from "zustand";
import { AssemblyClient, OpenAPIConfig } from "../openapi";
import { GridPaginationModel } from "@mui/x-data-grid";

import { Heatle, AppState } from "./types";

console.log(process.env);

const apiConfig: OpenAPIConfig = {
    BASE: process.env.REACT_APP_BACKEND_ADDRESS as string,
    WITH_CREDENTIALS: false,
    CREDENTIALS: "omit",
    VERSION: "3.0.3",
};

export default create<AppState>()((set, get) => ({
    GODMODE: true,
    history: [],
    user: "",
    loggedIn: false,
    loginMessage: "",
    api: new AssemblyClient(apiConfig),
    heatles: [],
    selectedHeatles: [],
    changingHeatle: true,
    modalOpen: false,
    scanned: "",
    parts: {},
    settings: {},
    count: 0,
    pagination: {
        page: 0,
        pageSize: 10,
    },

    login: async (email: string, password: string) => {
        try {
            let token = "";
            if (email === "example@heatle.de") {
                set({
                    loginMessage: "Forbidden",
                });
                return;
            }
            if (email === "token") {
                token = localStorage.getItem("token") || "";
                email = localStorage.getItem("user") || "";
            } else
                token = (
                    await get().api.login.postLogin({
                        email,
                        password,
                    })
                ).token;

            if (token !== "") {
                set({
                    user: email,
                    loggedIn: true,
                    api: new AssemblyClient({
                        ...apiConfig,
                        HEADERS: {
                            Authorization: token,
                        },
                    }),
                });

                await get().loadParts();
                await get().loadHeatles(get().pagination);
                await fetch(apiConfig.BASE + "/heatle/cnt", {
                    headers: {
                        Authorization: token,
                    },
                })
                    .then(data => data.json())
                    .then(json => {
                        set({ count: json.number });
                    });

                localStorage.setItem("token", token);
                localStorage.setItem("user", email);
            }
        } catch (e) {
            localStorage.setItem("token", "");
            console.log(e);
        }
    },

    logout: () => {
        set({
            user: "",
            loggedIn: false,
            api: new AssemblyClient(apiConfig),
        });
        localStorage.setItem("token", "");
        localStorage.setItem("user", "");
    },

    register: (
        email: string,
        password: string,
        firstname: string,
        lastname: string,
        roles: number[]
    ) => {
        get().api.employee.postEmployee({
            email,
            firstname,
            lastname,
            password,
            role_ids: [],
        });
    },

    closeDetail: () => {
        set({
            modalOpen: false,
        });
    },

    selectHeatles: (...IDs: Array<number>) => {
        set({
            selectedHeatles: IDs,
            changingHeatle: {
                ...get().heatles.find(heatle => heatle.id === IDs[0]),
            },
        });
    },

    loadHeatles: async ({ page, pageSize }: GridPaginationModel) => {
        try {
            const api = get().api;

            const heatles: Array<Heatle> = (await api.heatle.getHeatle(
                pageSize,
                pageSize * page
            )) as any;

            set({ heatles: heatles });

            get().selectHeatles(heatles[0].id);
        } catch (e) {
            console.log(e);
        }
    },

    updatePagination: (model: GridPaginationModel) =>
        set({
            pagination: model,
        }),

    changeHeatle: (data: any) => {
        set({
            changingHeatle: Object.assign({}, get().changingHeatle, data),
        });
    },

    updateHeatle: async (heatle: Heatle) => {
        const api = get().api;
        const data = {
            ...heatle,
        };

        try {
            await api.heatle.patchHeatle(data.id, data as any);

            const heatles = get().heatles;
            const updated = heatles.findIndex(item => item.id === data.id);

            if (updated > 0)
                set({
                    heatles: [
                        ...heatles.slice(0, updated),
                        heatle,
                        ...heatles.slice(updated + 1),
                    ],
                    history: [`updated heatle ${heatle.id}`, ...get().history],
                });
        } catch {
            set({
                history: [
                    `failed to update heatle ${heatle.id}`,
                    ...get().history,
                ],
            });
        }
    },

    deleteLid: async (id: number) => {
        const result = get().api.lid.deleteHeatleLid(id);
        set({ history: [`deleted lid ${id} (${result})`, ...get().history] });
    },

    deletePCB: async (id: number) => {
        const result = get().api.pcb.deletePcb(id);
        set({ history: [`deleted PCB ${id} (${result})`, ...get().history] });
    },

    loadParts: async () => {
        const api = get().api;

        const parts = await Promise.all([
            api.part.getHeatleColor(),
            api.part.getHeatleCoil(),
            api.part.getHeatleLedPcb(),
            api.part.getHeatleButton(),
            api.part.getHeatleButtonPcb(),
            api.part.getHeatleHeatsink(),
            api.part.getHeatleFan(),
            api.part.getHeatleSticker(),
            api.part.getHeatleGlass(),
            api.part.getHeatleWire(),
            api.part.getHeatleMosfet(),
            api.part.getHeatleGlue(),
        ]);

        const [
            color_id,
            coil_id,
            led_pcb_id,
            button_id,
            button_pcb_id,
            heatsink_id,
            fan_id,
            sticker_id,
            glass_id,
            mains_wire_id,
            mosfet_id,
            glue_id,
        ] = parts;

        set({
            parts: {
                color_id,
                button_id,
                mains_wire_id,
                button_pcb_id,
                led_pcb_id,
                coil_id,
                mosfet_id,
                fan_id,
                heatsink_id,
                sticker_id,
                glass_id,
                glue_id,
            },
        });
    },

    scanQR: async (value: string) => {
        const patterns = {
            action: /---([\w]*)/g,
            lid: /^https:\/\/.+\/([a-zA-Z0-9]+)$/,
            pcb: /^([a-zA-Z]+-)+[a-zA-Z0-9]+$/,
            // psoc: /????/ ,
        };

        const matched = Object.entries(patterns).reduce(
            (match: string | undefined, item: any) => {
                if (match) return match;
                const [name, pattern] = item;
                if (value.match(pattern)) {
                    return name;
                }
            },
            undefined
        );

        set({
            scanned: value,
            history: [`scanned: ${value} (${matched})`, ...get().history],
        });

        if (get().loggedIn) {
            switch (matched) {
                case "action": {
                    const [action, ...params]: Array<string> = (
                        value.match(patterns.action) as any
                    ).map((match: string) => match.replace("---", ""));

                    switch (action) {
                        case "cycle": {
                            const field: string = params[0];
                            const options = (get().parts as any)[field];
                            const current = get().changingHeatle[field];
                            const index = options.findIndex(
                                (item: any) => item.id === current
                            );
                            const next = (index + 1) % options.length;

                            set({
                                changingHeatle: {
                                    ...get().changingHeatle,
                                    [field]: options[next].id,
                                },
                            });
                            break;
                        }

                        case "change": {
                            const value: string = prompt("scan new lid:") || "";
                            if (!value) return;

                            const newSnr = (
                                value.match(patterns.lid) as any
                            )[1];

                            const [lid]: any = await get().api.lid.getHeatleLid(
                                1,
                                undefined,
                                undefined,
                                newSnr
                            );

                            if (!lid) {
                                try {
                                    var newLid: any =
                                        await get().api.lid.postHeatleLid({
                                            comment:
                                                "added for heatle " +
                                                get().changingHeatle.id,
                                            lid_serial_number: newSnr,
                                        });
                                    set({
                                        history: [
                                            `registered a new lid ${newSnr}(${newLid?.id})`,
                                            ...get().history,
                                        ],
                                    });
                                } catch {
                                    set({
                                        history: [
                                            `failed register a new lid ${newSnr}`,
                                            ...get().history,
                                        ],
                                    });
                                }
                            }

                            set({
                                changingHeatle: {
                                    ...get().changingHeatle,
                                    lid_id: lid?.id || newLid?.id,
                                    comment: `updated lid for '${
                                        get().changingHeatle.QR_pcb
                                    }' to '${newSnr}'`,
                                },
                            });
                            break;
                        }
                        case "delete": {
                            const [param] = params;
                            if (param === "lid") {
                                get().deleteLid(get().changingHeatle.lid_id);
                                get().changeHeatle({ lid_id: null });
                            }

                            if (param === "pcb") {
                                get().deletePCB(get().changingHeatle.pcb_id);
                                get().changeHeatle({ pcb_id: null });
                            }
                            get().updateHeatle(get().changingHeatle);
                            break;
                        }
                        case "changes": {
                            const [param] = params;
                            if (param === "save") {
                                get().updateHeatle(get().changingHeatle);
                                get().closeDetail();
                            }
                            if (param === "cancel") {
                                get().closeDetail();
                            }
                            break;
                        }
                        case "GODMODE": {
                            set({
                                GODMODE: !get().GODMODE,
                            });
                        }
                    }
                    break;
                }
                case "pcb": {
                    get().getQRPCB(value);
                    break;
                }
                case "lid": {
                    get().getQRLid((value.match(patterns.lid) as any)[1]);
                    break;
                }
            }
        }
    },

    getQRPCB: async (snr: string) => {
        const api = get().api;
        const [pcb]: any = await api.pcb.getPcb(
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            snr
        );

        if (!pcb) {
            set({
                history: [
                    `PCB from QR ${snr} not found in DB`,
                    ...get().history,
                ],
            });
            return;
        }
        // try
        try {
            const [heatle]: any = await api.heatle.getHeatle(
                1,
                undefined,
                undefined,
                pcb.id
            );

            set({
                changingHeatle: heatle,
                modalOpen: true,
                history: [`loaded heatle ${heatle.id}`, ...get().history],
            });
        } catch (e) {
            set({
                history: [
                    `failed to load heatle for PCB ${snr}(${pcb.id})`,
                    ...get().history,
                ],
            });
        }
    },

    getQRLid: async (snr: string) => {
        const api = get().api;
        const [lid]: any = await api.lid.getHeatleLid(
            1,
            undefined,
            undefined,
            snr
        );

        try {
            const [heatle]: any = await api.heatle.getHeatle(
                1,
                undefined,
                undefined,
                undefined,
                lid.id
            );

            set({ changingHeatle: heatle, modalOpen: true });
        } catch (e) {
            console.log(e);
        }
    },

    showDetail: (heatle: Heatle) => {
        set({ changingHeatle: heatle, modalOpen: true });
    },

    log: (msg: string) => {
        set({ history: [...get().history, msg] });
    },
}));
