/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FirmwareType } from '../models/FirmwareType';
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FirmwareService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get multiple firmware
     * @param limit 
     * @param offset 
     * @param direction 
     * @param firmwareType 
     * @param name 
     * @param obsoleted 
     * @param overTheAir 
     * @param major 
     * @param minor 
     * @param patch 
     * @param hardwareId 
     * @param filename 
     * @returns void 
     * @throws ApiError
     */
    public getFirmware(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
firmwareType?: FirmwareType,
name?: string,
obsoleted?: boolean,
overTheAir?: boolean,
major?: number,
minor?: number,
patch?: number,
hardwareId?: number,
filename?: string,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/firmware/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
                'firmware_type': firmwareType,
                'name': name,
                'obsoleted': obsoleted,
                'over_the_air': overTheAir,
                'major': major,
                'minor': minor,
                'patch': patch,
                'hardware_id': hardwareId,
                'filename': filename,
            },
        });
    }

    /**
     * Creates new firmware
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postFirmware(
requestBody?: {
comment: string;
filename: string;
hardware_id: number;
major: number;
minor: number;
name: string;
obsoleted: boolean;
over_the_air: boolean;
patch: number;
type: FirmwareType;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/firmware/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns firmware data by id
     * @param firmwareId 
     * @returns any FirmwareData(data: 'str')
     * @throws ApiError
     */
    public getFirmwareData(
firmwareId: number,
): CancelablePromise<{
data: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/firmware/data/{firmware_id}',
            path: {
                'firmware_id': firmwareId,
            },
        });
    }

    /**
     * Uploads firmware data
     * @param firmwareId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postFirmwareData(
firmwareId: number,
requestBody?: {
data: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/firmware/data/{firmware_id}',
            path: {
                'firmware_id': firmwareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete firmware
     * @param firmwareId 
     * @returns void 
     * @throws ApiError
     */
    public deleteFirmware(
firmwareId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/firmware/{firmware_id}',
            path: {
                'firmware_id': firmwareId,
            },
        });
    }

    /**
     * Returns firmware by id
     * @param firmwareId 
     * @returns any Firmware(type: 'FirmwareType', name: 'str', obsoleted: 'bool', over_the_air: 'bool', comment: 'Optional[str]', major: 'int', minor: 'int', patch: 'int', hardware_id: 'int', filename: 'str', id: 'int')
     * @throws ApiError
     */
    public getFirmware1(
firmwareId: number,
): CancelablePromise<{
comment: string;
filename: string;
hardware_id: number;
id: number;
major: number;
minor: number;
name: string;
obsoleted: boolean;
over_the_air: boolean;
patch: number;
type: FirmwareType;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/firmware/{firmware_id}',
            path: {
                'firmware_id': firmwareId,
            },
        });
    }

    /**
     * Update firmware
     * @param firmwareId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchFirmware(
firmwareId: number,
requestBody?: {
comment: string;
filename: string;
hardware_id: number;
major: number;
minor: number;
name: string;
obsoleted: boolean;
over_the_air: boolean;
patch: number;
type: FirmwareType;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/firmware/{firmware_id}',
            path: {
                'firmware_id': firmwareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
