import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useStore from "../../Store";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";

const fieldSelect = (
    name: string,
    options: Array<{ id: number; name: string }>,
    update: Function
) => ({
    field: name,
    headerName: name.charAt(0).toUpperCase() + name.slice(1).replace("_id", ""),
    width: 150,
    editable: true,
    renderCell: (params: any) => {
        return options.find(item => item.id === params.value)?.name;
    },
    renderEditCell: (params: any) => {
        return (
            <FormControl fullWidth>
                <Select
                    value={params.value || ""}
                    autoFocus={true}
                    onChange={e => {
                        params.api.setEditCellValue({
                            ...params,
                            value: e.target.value,
                        });
                        update({ ...params.row, [name]: e.target.value });
                    }}
                >
                    {options.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    },
});

function Table() {
    const heatles = useStore(state => state.heatles);
    const load = useStore(state => state.loadHeatles);
    const pagination = useStore(state => state.pagination);
    const updatePagination = useStore(state => state.updatePagination);
    const count = useStore(state => state.count);
    const selectHeatles = useStore(state => state.selectHeatles);
    const selectedHeatles = useStore(state => state.selectedHeatles);
    const updateHeatle = useStore(state => state.updateHeatle);
    const showDetail = useStore(state => state.showDetail);
    const parts: any = useStore(state => state.parts);

    const GODMODE = useStore(state => state.GODMODE);
    if (!GODMODE) return <></>;

    const columns = Object.entries(parts).map(([name, values]) =>
        fieldSelect(name, values as any, updateHeatle)
    );
    columns.unshift(
        {
            field: "id",
            headerName: "Heatle ID",
            width: 70,
            renderCell: (params: any) => {
                return (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => showDetail(params.row)}
                    >
                        {params.value}
                    </span>
                );
            },
        } as any,
        {
            field: "pcb_id",
            headerName: "PCB ID",
            width: 70,
        } as any,
        {
            field: "lid_id",
            headerName: "Lid ID",
            width: 70,
        } as any
    );

    return (
        <DataGrid
            columns={columns}
            rows={heatles}
            checkboxSelection
            rowSelectionModel={selectedHeatles}
            onCellClick={params => selectHeatles(params.row.id)}
            paginationMode="server"
            pageSizeOptions={[10, 25, 50]}
            paginationModel={pagination}
            rowCount={count}
            onPaginationModelChange={model => {
                updatePagination(model);
                load(model);
            }}
        />
    );
}

export default Table;
