import React from "react";
import Login from "./pages/Login/LoginPage";
import Table from "./pages/Table/Table";
import useStore from "./Store";
import Detail from "./pages/Table/Detail";
import Log from "./pages/History";

const { getState } = useStore;

const QRScanner: {
    scanned: string;
    lastEvent?: KeyboardEvent;
    timeout?: NodeJS.Timeout;
} = {
    scanned: "",
    lastEvent: undefined,
    timeout: undefined,
};
document.onkeydown = e => {
    if (!e.key) return;
    if (QRScanner.lastEvent) {
        const timeDiff =
            e.timeStamp - (QRScanner.lastEvent as KeyboardEvent).timeStamp;
        if (timeDiff < 15) {
            clearTimeout(QRScanner.timeout);
            QRScanner.scanned += e.key.length > 1 ? "" : e.key;
            QRScanner.lastEvent = e;
        }
        QRScanner.timeout = setTimeout(() => {
            console.log(QRScanner.scanned);
            if (QRScanner.scanned.length > 1) {
                getState().scanQR(QRScanner.scanned);
            }
            QRScanner.lastEvent = undefined;
        }, 20);
    } else {
        QRScanner.scanned = e.key.length > 1 ? "" : e.key;
        QRScanner.lastEvent = e;
    }
};

function App() {
    const loggedIn = useStore(state => state.loggedIn);
    const login = useStore(state => state.login);

    login("token");

    return (
        <div className="App">
            <header className="App-header">
                <Login />
            </header>

            <Detail />

            {loggedIn && <Table />}
            <Log />
        </div>
    );
}

export default App;
