/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LidService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get many lids
     * @param limit 
     * @param offset 
     * @param direction 
     * @param lidSerialNumber 
     * @returns void 
     * @throws ApiError
     */
    public getHeatleLid(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
lidSerialNumber?: string,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-lid/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
                'lid_serial_number': lidSerialNumber,
            },
        });
    }

    /**
     * Creates new lid
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHeatleLid(
requestBody?: {
comment: string;
lid_serial_number: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/heatle-lid/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete lid
     * @param lidId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHeatleLid(
lidId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/heatle-lid/{lid_id}',
            path: {
                'lid_id': lidId,
            },
        });
    }

    /**
     * Get lid by id
     * @param lidId 
     * @returns any HeatleLid(comment: Optional[str], lid_serial_number: str, id: int)
     * @throws ApiError
     */
    public getHeatleLid1(
lidId: number,
): CancelablePromise<{
comment: string;
id: number;
lid_serial_number: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/heatle-lid/{lid_id}',
            path: {
                'lid_id': lidId,
            },
        });
    }

    /**
     * Update lid
     * @param lidId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHeatleLid(
lidId: number,
requestBody?: {
comment: string;
lid_serial_number: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/heatle-lid/{lid_id}',
            path: {
                'lid_id': lidId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
