/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Role } from '../models/Role';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmployeeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all employees
     * @returns void 
     * @throws ApiError
     */
    public getEmployee(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/employee/',
        });
    }

    /**
     * Register new employee
     * @param requestBody 
     * @returns any Id(id: int)
     * @throws ApiError
     */
    public postEmployee(
requestBody?: {
email: string;
firstname: string;
lastname: string;
password: string;
role_ids: Array<number>;
},
): CancelablePromise<{
id: number;
}> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/employee/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete employee
     * @param employeeId 
     * @returns any Employee(id: int, email: str, firstname: str, lastname: str, roles: list[app.auth_dto.Role.Role])
     * @throws ApiError
     */
    public deleteEmployee(
employeeId: number,
): CancelablePromise<{
email: string;
firstname: string;
id: number;
lastname: string;
roles: Array<Role>;
}> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/employee/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
        });
    }

    /**
     * Get single employee
     * @param employeeId 
     * @returns any Employee(id: int, email: str, firstname: str, lastname: str, roles: list[app.auth_dto.Role.Role])
     * @throws ApiError
     */
    public getEmployee1(
employeeId: number,
): CancelablePromise<{
email: string;
firstname: string;
id: number;
lastname: string;
roles: Array<Role>;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/employee/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
        });
    }

    /**
     * Update employee roles
     * @param employeeId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchEmployee(
employeeId: number,
requestBody?: {
role_ids: Array<number>;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/employee/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
