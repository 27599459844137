/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create employee token
     * @param requestBody 
     * @returns any Token(token: str)
     * @throws ApiError
     */
    public postLogin(
requestBody?: {
email: string;
password: string;
},
): CancelablePromise<{
token: string;
}> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check if user logged in
     * @returns void 
     * @throws ApiError
     */
    public getLoginPing(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login/ping',
        });
    }

    /**
     * Refresh employee token
     * @param requestBody 
     * @returns any Token(token: str)
     * @throws ApiError
     */
    public postLoginRefresh(
requestBody?: {
token: string;
},
): CancelablePromise<{
token: string;
}> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/refresh',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
