/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { EmployeeService } from './services/EmployeeService';
import { FirmwareService } from './services/FirmwareService';
import { HardwareService } from './services/HardwareService';
import { HeatleService } from './services/HeatleService';
import { LidService } from './services/LidService';
import { LoggingService } from './services/LoggingService';
import { LoginService } from './services/LoginService';
import { ManufacturerService } from './services/ManufacturerService';
import { ManufacturingDefaultService } from './services/ManufacturingDefaultService';
import { MetricsService } from './services/MetricsService';
import { PartService } from './services/PartService';
import { PcbService } from './services/PcbService';
import { PermissionService } from './services/PermissionService';
import { RoleService } from './services/RoleService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AssemblyClient {

    public readonly employee: EmployeeService;
    public readonly firmware: FirmwareService;
    public readonly hardware: HardwareService;
    public readonly heatle: HeatleService;
    public readonly lid: LidService;
    public readonly logging: LoggingService;
    public readonly login: LoginService;
    public readonly manufacturer: ManufacturerService;
    public readonly manufacturingDefault: ManufacturingDefaultService;
    public readonly metrics: MetricsService;
    public readonly part: PartService;
    public readonly pcb: PcbService;
    public readonly permission: PermissionService;
    public readonly role: RoleService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.employee = new EmployeeService(this.request);
        this.firmware = new FirmwareService(this.request);
        this.hardware = new HardwareService(this.request);
        this.heatle = new HeatleService(this.request);
        this.lid = new LidService(this.request);
        this.logging = new LoggingService(this.request);
        this.login = new LoginService(this.request);
        this.manufacturer = new ManufacturerService(this.request);
        this.manufacturingDefault = new ManufacturingDefaultService(this.request);
        this.metrics = new MetricsService(this.request);
        this.part = new PartService(this.request);
        this.pcb = new PcbService(this.request);
        this.permission = new PermissionService(this.request);
        this.role = new RoleService(this.request);
    }
}
