/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManufacturerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all manufacturers
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getPcbManufacturer(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pcb-manufacturer/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new manufacturer
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postPcbManufacturer(
requestBody?: {
name: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pcb-manufacturer/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete manufacturer
     * @param manufacturerId 
     * @returns void 
     * @throws ApiError
     */
    public deletePcbManufacturer(
manufacturerId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/pcb-manufacturer/{manufacturer_id}',
            path: {
                'manufacturer_id': manufacturerId,
            },
        });
    }

    /**
     * Get manufacturer by id
     * @param manufacturerId 
     * @returns any PCBManufacturer(name: str, id: int)
     * @throws ApiError
     */
    public getPcbManufacturer1(
manufacturerId: number,
): CancelablePromise<{
id: number;
name: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pcb-manufacturer/{manufacturer_id}',
            path: {
                'manufacturer_id': manufacturerId,
            },
        });
    }

    /**
     * Update manufacturer
     * @param manufacturerId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchPcbManufacturer(
manufacturerId: number,
requestBody?: {
name: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pcb-manufacturer/{manufacturer_id}',
            path: {
                'manufacturer_id': manufacturerId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
