import React, { useState } from "react";
import useStore from "../../Store";
import { Button, Container } from "@mui/material";
import QRCode from "react-qr-code";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [registering, setRegistering] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const user = useStore(state => state.user);

    const { login, logout, loggedIn, loginMessage, register } = useStore(
        state => ({
            login: state.login,
            logout: state.logout,
            loggedIn: state.loggedIn,
            loginMessage: state.loginMessage,
            register: state.register,
        })
    );

    return (
        <Container>
            {loggedIn ? (
                <>
                    {user}
                    <Button onClick={() => logout()}>Logout</Button>
                    <QRCode
                        value="---GODMODE"
                        style={{
                            width: "30px",
                            height: "30px",
                            paddingTop: "5px",
                        }}
                    ></QRCode>
                </>
            ) : (
                <Container>
                    <Container>
                        <input
                            id="email"
                            value={email}
                            placeholder="email"
                            onChange={e => setEmail(e.target.value)}
                        />

                        <input
                            type="password"
                            value={password}
                            placeholder="password"
                            onChange={e => setPassword(e.target.value)}
                        />
                        {loginMessage}
                    </Container>
                    {registering && (
                        <Container>
                            <input
                                value={firstName}
                                placeholder="first name"
                                onChange={e => setFirstName(e.target.value)}
                            ></input>

                            <input
                                value={lastName}
                                placeholder="last name"
                                onChange={e => setLastName(e.target.value)}
                            ></input>
                        </Container>
                    )}
                    {!registering && (
                        <Button onClick={() => login(email, password)}>
                            Login
                        </Button>
                    )}
                    {registering && (
                        <Button
                            onClick={() =>
                                register(email, password, firstName, lastName)
                            }
                        >
                            Register
                        </Button>
                    )}
                    {/* <Button onClick={() => setRegistering(value => !value)}> 
                        {registering ? "Cancel" : "Register"}
                    </Button>
                    */}
                </Container>
            )}
        </Container>
    );
}
export default Login;
