import { Container } from "@mui/material";
import useStore from "../Store";

function History() {
    const history = useStore(state => state.history);
    return (
        <Container>
            {history.map((item, i) => (
                <p key={i}>{item}</p>
            ))}
        </Container>
    );
}

export default History;
