/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoggingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postLogging(
requestBody?: {
level: 0 | 10 | 20 | 30 | 40 | 50;
msg: string;
seconds_since_epoch: number;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/logging/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
