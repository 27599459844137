import {
    Button,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputLabel,
    Typography,
    Container,
} from "@mui/material";

import useStore from "../../Store";
import QRCode from "react-qr-code";
import { Heatle } from "../../Store/types";
import { useState } from "react";

function Dropdown(
    field: string,
    options: Array<{ id: number; name: string }>,
    heatle: Heatle,
    update: Function
) {
    return (
        <Grid item key={field} width={"50%"}>
            <FormControl style={{ width: "85%", marginBottom: "1rem" }}>
                {heatle && (
                    <>
                        <InputLabel
                            style={{
                                background: "white",
                                color: "black",
                                fontWeight: 700,
                                padding: "3px",
                            }}
                            id={field}
                        >
                            {field}
                        </InputLabel>
                        <Select
                            labelId={field}
                            value={(heatle as any)[field]}
                            onChange={e => update({ [field]: e.target.value })}
                        >
                            {options.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}
            </FormControl>
            <QRCode
                value={"---cycle---" + field}
                style={{ width: "50px", height: "50px", padding: "3px" }}
            ></QRCode>
        </Grid>
    );
}

function Detail() {
    // const heatles = useStore(state => state.heatles);
    const user = useStore(state => state.user);
    const changingHeatle = useStore(state => state.changingHeatle);
    const changeHeatle = useStore(state => state.changeHeatle);
    const update = useStore(state => state.updateHeatle);
    const parts = useStore(state => state.parts);
    const shown = useStore(state => state.modalOpen);
    const close = useStore(state => state.closeDetail);
    const scan = useStore(state => state.scanQR);

    const Dropdowns = Object.entries(parts).map(([name, values]) =>
        Dropdown(name, values as any, changingHeatle, changeHeatle)
    );

    const [comment, setComment] = useState("");
    // comment::user::timestamp
    const comments =
        changingHeatle.comment === ""
            ? []
            : changingHeatle.comment
                  ?.split("\n")
                  .map((line: string) => line.split("::"));

    const addComment = () => {
        if (comment) {
            const newComment = [comment, user, Date.now()].join("::");
            changeHeatle({
                comment:
                    changingHeatle.comment === ""
                        ? newComment
                        : changingHeatle.comment + "\n" + newComment,
            });
            setComment("");
        }
    };

    const deleteComment = (index: number) => {
        changeHeatle({
            comment: changingHeatle.comment
                .split("\n")
                .toSpliced(index, 1)
                .join("\n"),
        });
    };

    return changingHeatle?.id ? (
        <Dialog open={shown} onClose={close as any} fullScreen>
            <DialogTitle align="left">
                <Typography>Heatle {changingHeatle.id}</Typography>
                <Button onClick={() => scan("---delete---pcb")}>
                    DELETE PCB
                    <QRCode
                        value={"---delete---pcb"}
                        style={{
                            width: "50px",
                            height: "50px",
                            padding: "5px",
                        }}
                    ></QRCode>
                </Button>
                <Button onClick={() => scan("---delete---lid")}>
                    DELETE LID:
                    <QRCode
                        value={"---delete---lid"}
                        style={{
                            width: "50px",
                            height: "50px",
                            padding: "5px",
                        }}
                    ></QRCode>
                </Button>
                <Button onClick={() => scan("---change---lid")}>
                    CHANGE LID:
                    <QRCode
                        value={"---change---lid"}
                        style={{
                            width: "50px",
                            height: "50px",
                            padding: "5px",
                        }}
                    ></QRCode>
                </Button>
            </DialogTitle>
            <DialogContent
                style={{
                    padding: "1rem",
                }}
            >
                <Container>
                    Comments({comments.length}):
                    {comments.map(
                        (
                            [comment, user, time]: [string, string, string],
                            index: number
                        ) => (
                            <p key={time}>
                                {`${
                                    time
                                        ? new Date(
                                              parseInt(time)
                                          ).toLocaleString()
                                        : ""
                                } by ${user || ""}: ${comment}`}
                                <Button
                                    onClick={() => deleteComment(index)}
                                    style={{ color: "red" }}
                                >
                                    x
                                </Button>
                            </p>
                        )
                    )}
                    <p>
                        <input
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                        ></input>
                        <Button onClick={addComment}>Add comment</Button>
                    </p>
                </Container>
                <Grid container spacing={2} width={"100%"} direction={"row"}>
                    {Dropdowns}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close as any}>
                    Cancel
                    <QRCode
                        value={"---changes---cancel"}
                        style={{
                            width: "50px",
                            height: "50px",
                            padding: "5px",
                        }}
                    ></QRCode>
                </Button>
                <Button
                    onClick={async () => {
                        await update(changingHeatle);
                        close();
                    }}
                >
                    Save Changes
                    <QRCode
                        value={"---changes---save"}
                        style={{
                            width: "50px",
                            height: "50px",
                            padding: "5px",
                        }}
                    ></QRCode>
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <></>
    );
}

export default Detail;
