/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDirection } from "../models/OrderDirection";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class HeatleService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get many heatles
     * @param limit
     * @param offset
     * @param direction
     * @param pcbId
     * @param lidId
     * @param colorId
     * @param coilId
     * @param ledPcbId
     * @param buttonId
     * @param buttonPcbId
     * @param heatsinkId
     * @param fanId
     * @param stickerId
     * @param glassId
     * @param mainsWireId
     * @param mosfetId
     * @param glueId
     * @returns void
     * @throws ApiError
     */
    public getHeatle(
        limit: number = 20,
        offset?: number,
        direction?: OrderDirection,
        pcbId?: number,
        lidId?: number,
        colorId?: number,
        coilId?: number,
        ledPcbId?: number,
        buttonId?: number,
        buttonPcbId?: number,
        heatsinkId?: number,
        fanId?: number,
        stickerId?: number,
        glassId?: number,
        mainsWireId?: number,
        mosfetId?: number,
        glueId?: number
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: "GET",
            url: "/heatle/",
            query: {
                limit: limit,
                offset: offset,
                direction: direction,
                pcb_id: pcbId,
                lid_id: lidId,
                color_id: colorId,
                coil_id: coilId,
                led_pcb_id: ledPcbId,
                button_id: buttonId,
                button_pcb_id: buttonPcbId,
                heatsink_id: heatsinkId,
                fan_id: fanId,
                sticker_id: stickerId,
                glass_id: glassId,
                mains_wire_id: mainsWireId,
                mosfet_id: mosfetId,
                glue_id: glueId,
            },
        });
    }

    /**
     * Creates new heatle
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public postHeatle(requestBody?: {
        button_id: number;
        button_pcb_id: number;
        coil_id: number;
        color_id: number;
        comment: string;
        fan_id: number;
        glass_id: number;
        glue_id: number;
        heatsink_id: number;
        led_pcb_id: number;
        lid_id: number;
        mains_wire_id: number;
        mosfet_id: number;
        pcb_id: number;
        sticker_id: number;
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: "POST",
            url: "/heatle/",
            body: requestBody,
            mediaType: "application/json",
        });
    }

    /**
     * Delete heatle
     * @returns any Number(number: int)
     * @throws ApiError
     */
    public getHeatleCnt(): CancelablePromise<{
        number: number;
    }> {
        return this.httpRequest.request({
            method: "GET",
            url: "/heatle/cnt",
        });
    }

    /**
     * Delete heatle
     * @param heatleId
     * @returns void
     * @throws ApiError
     */
    public deleteHeatle(heatleId: number): CancelablePromise<void> {
        return this.httpRequest.request({
            method: "DELETE",
            url: "/heatle/{heatle_id}",
            path: {
                heatle_id: heatleId,
            },
        });
    }

    /**
     * Get heatle by id
     * @param heatleId
     * @returns any Heatle(comment: Optional[str], pcb_id: int, lid_id: int, color_id: int, coil_id: int, led_pcb_id: int, button_id: int, button_pcb_id: int, heatsink_id: int, fan_id: int, sticker_id: int, glass_id: int, mains_wire_id: int, mosfet_id: int, glue_id: int, id: int, uptime: int, watt_consumed: int)
     * @throws ApiError
     */
    public getHeatle1(heatleId: number): CancelablePromise<{
        button_id: number;
        button_pcb_id: number;
        coil_id: number;
        color_id: number;
        comment: string;
        fan_id: number;
        glass_id: number;
        glue_id: number;
        heatsink_id: number;
        id: number;
        led_pcb_id: number;
        lid_id: number;
        mains_wire_id: number;
        mosfet_id: number;
        pcb_id: number;
        sticker_id: number;
        uptime: number;
        watt_consumed: number;
    }> {
        return this.httpRequest.request({
            method: "GET",
            url: "/heatle/{heatle_id}",
            path: {
                heatle_id: heatleId,
            },
        });
    }

    /**
     * Update heatle
     * @param heatleId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public patchHeatle(
        heatleId: number,
        requestBody?: {
            button_id: number;
            button_pcb_id: number;
            coil_id: number;
            color_id: number;
            comment: string;
            fan_id: number;
            glass_id: number;
            glue_id: number;
            heatsink_id: number;
            led_pcb_id: number;
            lid_id: number;
            mains_wire_id: number;
            mosfet_id: number;
            pcb_id: number;
            sticker_id: number;
        }
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: "PATCH",
            url: `/heatle/{heatle_id}`,
            path: {
                heatle_id: heatleId,
            },
            body: requestBody,
            mediaType: "application/json",
        });
    }
}
