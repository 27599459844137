/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PcbService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get many pcbs
     * @param limit 
     * @param offset 
     * @param direction 
     * @param heatleUuid0 
     * @param heatleUuid1 
     * @param secret 
     * @param pcbSerialNumber 
     * @param pcbManufacturerId 
     * @param hardwareId 
     * @param initialPsocFirmwareId 
     * @param initialNrfFirmwareId 
     * @param currentPsocFirmwareId 
     * @param currentNrfFirmwareId 
     * @param allowedPsocFirmwareId 
     * @param allowedNrfFirmwareId 
     * @returns void 
     * @throws ApiError
     */
    public getPcb(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
heatleUuid0?: number,
heatleUuid1?: number,
secret?: number,
pcbSerialNumber?: string,
pcbManufacturerId?: number,
hardwareId?: number,
initialPsocFirmwareId?: number,
initialNrfFirmwareId?: number,
currentPsocFirmwareId?: number,
currentNrfFirmwareId?: number,
allowedPsocFirmwareId?: number,
allowedNrfFirmwareId?: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pcb/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
                'heatle_uuid_0': heatleUuid0,
                'heatle_uuid_1': heatleUuid1,
                'secret': secret,
                'pcb_serial_number': pcbSerialNumber,
                'pcb_manufacturer_id': pcbManufacturerId,
                'hardware_id': hardwareId,
                'initial_psoc_firmware_id': initialPsocFirmwareId,
                'initial_nrf_firmware_id': initialNrfFirmwareId,
                'current_psoc_firmware_id': currentPsocFirmwareId,
                'current_nrf_firmware_id': currentNrfFirmwareId,
                'allowed_psoc_firmware_id': allowedPsocFirmwareId,
                'allowed_nrf_firmware_id': allowedNrfFirmwareId,
            },
        });
    }

    /**
     * Creates new pcb
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postPcb(
requestBody?: {
comment: string;
hardware_id: number;
heatle_uuid_0: number;
heatle_uuid_1: number;
nrf_firmware_id: number;
pcb_manufacturer_id: number;
pcb_serial_number: string;
psoc_firmware_id: number;
secret: number;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pcb/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete pcb
     * @param pcbId 
     * @returns void 
     * @throws ApiError
     */
    public deletePcb(
pcbId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/pcb/{pcb_id}',
            path: {
                'pcb_id': pcbId,
            },
        });
    }

    /**
     * Get pcb by id
     * @param pcbId 
     * @returns any 
     * @throws ApiError
     */
    public getPcb1(
pcbId: number,
): CancelablePromise<{
allowed_nrf_firmware_id: number;
allowed_psoc_firmware_id: number;
comment: string;
current_nrf_firmware_id: number;
current_psoc_firmware_id: number;
hardware_id: number;
heatle_uuid_0: number;
heatle_uuid_1: number;
id: number;
initial_nrf_firmware_id: number;
initial_psoc_firmware_id: number;
pcb_manufacturer_id: number;
pcb_serial_number: string;
secret: number;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pcb/{pcb_id}',
            path: {
                'pcb_id': pcbId,
            },
        });
    }

    /**
     * Update pcb
     * @param pcbId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchPcb(
pcbId: number,
requestBody?: {
allowed_nrf_firmware_id: number;
allowed_psoc_firmware_id: number;
comment: string;
current_nrf_firmware_id: number;
current_psoc_firmware_id: number;
hardware_id: number;
heatle_uuid_0: number;
heatle_uuid_1: number;
initial_nrf_firmware_id: number;
initial_psoc_firmware_id: number;
pcb_manufacturer_id: number;
pcb_serial_number: string;
secret: number;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pcb/{pcb_id}',
            path: {
                'pcb_id': pcbId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
