/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HardwareService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Gets hardware list
     * @param limit 
     * @param offset 
     * @param direction 
     * @param name 
     * @param hardwareVersion 
     * @returns void 
     * @throws ApiError
     */
    public getHardware(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
name?: string,
hardwareVersion?: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/hardware/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
                'name': name,
                'hardware_version': hardwareVersion,
            },
        });
    }

    /**
     * Creates new hardware
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postHardware(
requestBody?: {
comment: string;
hardware_version: number;
name: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/hardware/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete hardware
     * @param hardwareId 
     * @returns void 
     * @throws ApiError
     */
    public deleteHardware(
hardwareId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/hardware/{hardware_id}',
            path: {
                'hardware_id': hardwareId,
            },
        });
    }

    /**
     * Returns hardware by id/hardware_bits
     * @param hardwareId 
     * @returns any Hardware(name: 'str', comment: 'Optional[str]', hardware_version: 'int', id: 'int')
     * @throws ApiError
     */
    public getHardware1(
hardwareId: number,
): CancelablePromise<{
comment: string;
hardware_version: number;
id: number;
name: string;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/hardware/{hardware_id}',
            path: {
                'hardware_id': hardwareId,
            },
        });
    }

    /**
     * Update hardware
     * @param hardwareId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchHardware(
hardwareId: number,
requestBody?: {
comment: string;
hardware_version: number;
name: string;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/hardware/{hardware_id}',
            path: {
                'hardware_id': hardwareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
