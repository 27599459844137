/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManufacturingDefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all manufacturing defaults
     * @param limit 
     * @param offset 
     * @param direction 
     * @returns void 
     * @throws ApiError
     */
    public getManufacturingDefault(
limit: number = 20,
offset?: number,
direction?: OrderDirection,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/manufacturing-default/',
            query: {
                'limit': limit,
                'offset': offset,
                'direction': direction,
            },
        });
    }

    /**
     * Creates new manufacturing default
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public postManufacturingDefault(
requestBody?: {
button_id: number;
button_pcb_id: number;
coil_id: number;
color_id: number;
comment: string;
fan_id: number;
glass_id: number;
glue_id: number;
heatsink_id: number;
led_pcb_id: number;
mosfet_id: number;
name: string;
sticker_id: number;
wire_id: number;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/manufacturing-default/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete manufacturing default
     * @param manufacturingDefaultId 
     * @returns void 
     * @throws ApiError
     */
    public deleteManufacturingDefault(
manufacturingDefaultId: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/manufacturing-default/{manufacturing_default_id}',
            path: {
                'manufacturing_default_id': manufacturingDefaultId,
            },
        });
    }

    /**
     * Get manufacturing_default by id
     * @param manufacturingDefaultId 
     * @returns any ManufacturingDefault(name: str, comment: Optional[str], color_id: int, wire_id: int, button_id: int, coil_id: int, led_pcb_id: int, button_pcb_id: int, heatsink_id: int, fan_id: int, sticker_id: int, glass_id: int, mosfet_id: int, glue_id: int, id: int)
     * @throws ApiError
     */
    public getManufacturingDefault1(
manufacturingDefaultId: number,
): CancelablePromise<{
button_id: number;
button_pcb_id: number;
coil_id: number;
color_id: number;
comment: string;
fan_id: number;
glass_id: number;
glue_id: number;
heatsink_id: number;
id: number;
led_pcb_id: number;
mosfet_id: number;
name: string;
sticker_id: number;
wire_id: number;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/manufacturing-default/{manufacturing_default_id}',
            path: {
                'manufacturing_default_id': manufacturingDefaultId,
            },
        });
    }

    /**
     * Update manufacturing default
     * @param manufacturingDefaultId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public patchManufacturingDefault(
manufacturingDefaultId: number,
requestBody?: {
button_id: number;
button_pcb_id: number;
coil_id: number;
color_id: number;
comment: string;
fan_id: number;
glass_id: number;
glue_id: number;
heatsink_id: number;
led_pcb_id: number;
mosfet_id: number;
name: string;
sticker_id: number;
wire_id: number;
},
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/manufacturing-default/{manufacturing_default_id}',
            path: {
                'manufacturing_default_id': manufacturingDefaultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
